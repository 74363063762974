import { useAuth, isLatestAdminLogin } from '@IJGN/potential'
import type { MetaFunction } from '@remix-run/node'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useIsAdmin, useIsCompanyPerson } from '~/lib/role'
import { useSharedData } from '~/context/SharedDataProvider'
import CenterLoading from './components/Loading/Password'

export const meta: MetaFunction = () => {
  return [
    { title: 'New Remix App' },
    { name: 'description', content: 'Welcome to Remix!' },
  ]
}

export default function Index() {
  const { user } = useAuth()
  const { company } = useSharedData()
  const isAdmin = useIsAdmin()
  const isCompanyPerson = useIsCompanyPerson()
  const navigate = useNavigate()

  useEffect(() => {
    if (isAdmin) {
      navigate('/admin/dashboard')
    } else if (isCompanyPerson) {
      if (!user || !company) {
        navigate('/companies/mypage')
      } else {
        navigate(`/companies/${company?.code}/dashboard`)
      }
    } else {
      if (isLatestAdminLogin()) {
        navigate('/admin/login')
      } else {
        navigate('/login')
      }
    }
  }, [user, isAdmin, isCompanyPerson, company, navigate])

  return <CenterLoading />
}
